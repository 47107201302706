.App {
  text-align: center;
  width: 100%;
  height: 90vh;
  background-color: #F6F7F8;
}


/* mouse over link */
a:hover {
  color: rgb(231, 175, 21);
  text-decoration: none
}




.nav-item{
  align-content: space-around;
  text-decoration: none;
  list-style: none;
  margin-right: 18px;
  color:black;
  font-size: large;
  
}

.giph {
  display:flex;
  width:100%;
  align-content: center;
}

.p {
  font-weight: normal;
}

.h3 {
  font-weight: bold;
  
}

